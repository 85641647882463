import React from 'react'
import { useState, useEffect } from 'react'
import Modal from './Modal'

function WLpopup({popopen, func, text1,text2 ,win }) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
     setTimeout(()=>{
      setOpen(false);
      func();
     },1000) 
  };

  const handleOpen = () => {
    setOpen(true);   
  };
  useEffect(() => {
    if (popopen) {
      handleOpen();
    } 
  }, [popopen]);
  

  return (
    <>

    <Modal isOpen={open} onClose={handleClose}>
                <>
                    <h1 style={{'color':'white'}}>{text1}</h1>
                   { win ? <h3 style={{'color':'green'}}>{text2}</h3> : <h3 style={{'color':'red'}}>{text2}</h3>}
                </>
            </Modal>
    </>
  )
}

export default WLpopup