import { createContext ,useState,useEffect } from 'react';
// import { v4 as uuidv4, v4 } from 'uuid';
import axios from "axios";
// import cookie from "js-cookie";
const UserContext = createContext("");


export const UserProvider = ({ children }) => {
    const url = "http://api.test.goldkings.in";
    // const url = "http://localhost:3000"

    const [userId, setUserId] = useState("");
    const [userName, setUserName] = useState("");
    const [password, setPassword] = useState("");    
    const [user, setUser] = useState( );
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [role, setRole] = useState("");

    // Check login
    // useEffect(() => {
    //     const checkLogin = async () => {
    //         const response = await axios.get("http://localhost:3001/users/login", {
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 Authorization: `Bearer ${localStorage.getItem("token")}`,
    //             },
    //         });
    //         const data = await response.json();
    //         if (data.error) {
    //             setIsLoggedIn(false);
    //             return;
    //         }
    //         setUser(data);
    //     };
    //     checkLogin();
    // }, []);
    
    const fetchUserTokens = async () => {
        if (userId) {
            try {
                const response = await axios.get(`${url}/users/fetchUserTokens?userId=${userId}`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                const data = response.data;
                console.log(data);
                if (data.error) {
                    console.log(data.error);
                    return;
                }
                setUser(prevUser => ({ ...prevUser, tokens: data[0].Tokens }));
            } catch (error) {
                console.error("Failed to fetch user tokens:", error);
            }
        }
    };

    
    const handleLogout = () => {
        localStorage.removeItem("token");
        setIsLoggedIn(false);
        setUserName("");
        setUserId("");
        setRole("");
        setUser(null);
    }

    const handleLogin = async (userName, password) => {
        try {
            const response = await axios.post(`${url}/users/signin`, {
                userName,
                password
            }, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            
            const data = response.data;
            if (data.error) {
                console.log(data.error);
                return;
            }
            
            localStorage.setItem("token", data.token);
            document.cookie = `token=${data.token}`;
            setIsLoggedIn(true);
            setUserName(data.userName);
            setUserId(data.userId);
            setRole(data.role);
            setUser({userName: data.userName, userId: data.userId, role: data.role, tokens:0});
        } catch (error) {
            console.error("Login failed:", error);
        }
    };
    
    useEffect(() => {
        const fetchUserTokens = async () => {
            if (userId) {
                try {
                    const response = await axios.get(`${url}/users/fetchUserTokens?userId=${userId}`, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    });
                    const data = response.data;
                    console.log(data);
                    if (data.error) {
                        console.log(data.error);
                        return;
                    }
                    setUser(prevUser => ({ ...prevUser, tokens: data[0].Tokens }));
                } catch (error) {
                    console.error("Failed to fetch user tokens:", error);
                }
            }
        };
        fetchUserTokens();
    }, [userId]);


    
    return (
    <UserContext.Provider value={{fetchUserTokens ,handleLogin, handleLogout, user , isLoggedIn, setPassword, setUserName, userName, password }}>
        {children}  
    </UserContext.Provider>
    )
}   

export default UserContext;
