import React from "react";
import "./Button.css";
function Button({ text, addClass, color , width , func , params}) {
  const handleClick = (e) => {
    if (func && typeof func === 'function') {
      if ( params === undefined ){
        return () => func();
      }else{
        return () => func(...params);
      }
      
    }  
  };
  return (
    <div className={addClass} style={ width ? { width: width} : {}}>
      <button onClick={handleClick()}  className=" button-82-pushable " >
        <span className="button-82-shadow"></span>
        <span
          className={
            color === "green"
              ? "button-82-edge greenGradient"
              : "button-82-edge red340Gradient"
          }
          ></span>
        <span
          className={
            color === "green"
              ? "button-82-front text greenFront"
              : "button-82-front text red345Front"
          }
        >
          {text}
        </span>
      </button>
    </div>
  );
}

export default Button;
