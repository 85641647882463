// src/components/Loginform.jsx
import React from 'react';
import './Loginform.css';
import Button from '../../components/Helper/Button';
import { useContext } from 'react';
import UserContext from './UserContext';


const Loginform = () => {
  const { handleLogin, setPassword, setUserName,userName,password } = useContext(UserContext);
  return (
    <div className="login-form-container">
      <div className="login-form">
        <h2>Login</h2>
        <div className="form-group">
          <label htmlFor="username">Username:</label>
          <input onChange={(e) => setUserName(e.target.value)} type="text" id="username" name="username" />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input onChange={(e) => setPassword(e.target.value)} type="password" id="password" name="password" />
        </div>
        <Button text="Login" width="100%" color="red" func={handleLogin} params={[userName,password]} />                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     
      </div>
    </div>
  );
};

export default Loginform;