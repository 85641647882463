import React, { useEffect } from "react";
import "./BettingPad.css";
import { useContext } from "react";
import Button from "../../components/Helper/Button";
import  DiamondMinerContext  from "./DiamondMinerContext.jsx";
import UserContext from "../Login/UserContext.jsx"


function BettingPad( ) {
  const {gameId,setFinalBetAmount,cashOutAmount, incrementAmount, betAmount, setBetAmount, setNumBombs , numBombs } = useContext(DiamondMinerContext);
  const {user} = useContext(UserContext);
  console.log(user);
   
  useEffect(() => {
    if (betAmount > user.tokens) {
      setBetAmount(user.tokens);
      window.alert("You don't have enough tokens");
    }
    console.log("BettingPad useEffect");
  }, [betAmount, setBetAmount, user.tokens]);
  
  return (
    <div className="bettingPad">
      <div className="bettingPadInner">
        <div className="bettingPadClose">
          <div className="bettingLabels">
            <h1 className="bettingPadTitle">Betting Pad</h1>
            <span>{user.tokens}</span>
          </div>

          <input className="bettingInput"
            type="Number"
            placeholder="Enter Betting Amount..."
            value={betAmount}
            onChange={(e) => setBetAmount(e.target.value)}
            disabled={gameId ? true: false}
          />
          <div className="bettingPadButtons">
            <button disabled={gameId ? true: false} className="bettingPadButton" onClick={() => incrementAmount(5)}>+5</button>
            <button disabled={gameId ? true: false} className="bettingPadButton" onClick={() => incrementAmount(10)}>+10</button>
            <button disabled={gameId ? true: false} className="bettingPadButton" onClick={() => incrementAmount(50)}>+50</button>
          </div>
          <div className="bettingPadButtons">
            <button disabled={gameId ? true: false} className="bettingPadButton" onClick={() => incrementAmount(100)}>+100</button>
            <button disabled={gameId ? true: false} className="bettingPadButton" onClick={() => incrementAmount(500)}>+500</button>
            <button disabled={gameId ? true: false} className="bettingPadButton" onClick={() => incrementAmount(1000)}>+1000</button>
          </div>
          <div className="bettingLabels">
            <h3 className="bettingPadTitle">Number of Mines</h3>
          </div>
          <select className="bettingInput" name="No of Mines"  disabled={gameId ? true: false} onChange={(e) => setNumBombs(parseInt(e.target.value))}>
            {Array.from({length: 15}, (_, n) => n+3).map((x) => {
              return (<option d value={x} selected={ x === numBombs ? true:false} key={x}>{x}</option>);
            })}
          </select> 
          <Button text={"Bet"} color={"green"} width={"100%"} func={setFinalBetAmount} params={[user.userId]}/>
          <Button text={"Win"} color={"green"} width={"100%"} func={cashOutAmount} params={[user.userId]} />
        </div>
      </div>
    </div>
  );
}

export default BettingPad;
