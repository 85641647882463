import { createContext,useContext, useEffect } from "react";
import UserContext from "../Login/UserContext.jsx"
import { useState } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
const DiamondMinerContext = createContext();



function getBombLocations(numrows, numcols, numBombs) {
  const bombLocations = [];
  const max = numrows * numcols;
  for (let i = 0; i < numBombs; i++) {
    const bombLocation = Math.floor(Math.random() * max);
    if (!bombLocations.includes(bombLocation)) {
      bombLocations.push(bombLocation);
    } else {
      i--;
    }
  }
  return bombLocations;
}

function getSquareValue(bombLocations) {
  const squares = Array(5 * 5).fill(null);
  for (let i = 0; i < bombLocations.length; i++) {
    squares[bombLocations[i]] = "bomb";
  }
  return squares;
}

export const DiamondMinerProvider = ({ children }) => {
  const url = "http://api.test.goldkings.in";                                                                                                                                         
  // const url = "http://localhost:3000"
  const {user,fetchUserTokens} = useContext(UserContext);
  const [numBombs, setNumBombs] = useState(3);
  const [betAmount, setBetAmount] = useState(0);
  const [multiplier, setMultiplier] = useState(5 / (25 - 3));
  const [gameOver, setGameOver] = useState(false);
  const [squares, setSquares] = useState(getSquareValue(getBombLocations(5,5,0)));
  const [win, setWin] = useState(false);
  const [winVal, setWinVal] = useState(0);
  const [score, setScore] = useState(0);
  const [gameId, setGameId] = useState();
  const [loss, setLoss] = useState(false);

  const resetAllStates = () => {
  setNumBombs(3);
  setBetAmount(0);
  setMultiplier(1);
  setGameOver(false);
  setSquares(getSquareValue(getBombLocations(5,5,0)));
  setWin(false);
  setWinVal(0);
  setScore(0);
  setGameId();
  setLoss(false);
  fetchUserTokens();
}



  const incrementAmount = (amount) => {
  setBetAmount((prevAmount) => parseInt(prevAmount) + amount);
  console.log(`Incrementing by ${amount}`);
};

  // set final bet amount and actual game start
  const setFinalBetAmount = async (userId) => {
    const gameId = uuidv4();
    setGameId(gameId);
    try {
      const response = await axios.post(
        `${url}/diamondMiner/betAmount`,
        {
          betAmount: betAmount,
          userId: userId, // replace with actual userId
          gameId: gameId, // replace with actual gameId
          gameType: "DiamondMiner",
          numBombs: numBombs,
        },{
          headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
      }}
      );
      console.log("Bet amount set successfully:", response.data);
      fetchUserTokens();
    } catch (error) {
      console.error("Error setting bet amount:", error);
    }
  };

  // get bomblocation of particular square
  const getBombLocation = async(index,userId,gameId) => {
    // console.log( index,gameId,userId,"###############");
    try {
    const response = await axios.get(`${url}/diamondMiner/checkMine`, {
      params: {
        index: index,
        gameId: gameId,
        userId: userId,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    console.log("Bomb location:", response.data);
    const isBomb = response.data.message;
    if (response.data.bombLocations) {
      setSquares(response.data.bombLocations);
    }
    return isBomb;
    } catch (error) { 
      console.log("Error in getBombLocation", error);
    }
  }

  

  // cash out amount you have won
  const cashOutAmount = async(userId) => {
     setWin(true);
     setGameOver(true);
    await updateGame(userId,gameId,true,true,loss)
    await fetchUserTokens();
  };

  // check if user has won
  useEffect( () => {
    if (score === 25 - numBombs) {
      setWin(true);
       updateGame(user.userId,gameId,true,true,loss)
     fetchUserTokens();
    }
  }, [score, numBombs]);

  // calculate win amount
  useEffect(() => {
    if (win === true) {
      setWinVal(parseInt(multiplier * score * betAmount));
    }
  }, [win, betAmount, multiplier, score]);

  useEffect(() => { 
    setMultiplier(5 / (25 - numBombs));
    if (gameId === undefined) {
      // setSquares(getSquareValue(getBombLocations(5, 5, numBombs)));
    }
    else{
      setSquares(getSquareValue(getBombLocations(5, 5, 0)));
    }
  }, [gameId, numBombs]);

  
  


  
  // When game Id is created send request to backend to create a new game


  
// When User Wins
  const updateGame = async (userId,gameId,gameOver,win,loss) => {
    try {
      const response = await axios.post(
        `${url}/diamondMiner/updateGame`,
        {
          gameId: gameId,
          userId: userId,
          gameOver:gameOver,
          win:win,
          loss:loss,
        },{
          headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
      }}
      );
      console.log("Game over successfully:", response.data);
      if (response.data.bombLocations) {
        setSquares(response.data.bombLocations);
      }
      fetchUserTokens();
    } catch (error) {
      console.error("Error setting game over:", error);
    }
  };

  

  return (
    <DiamondMinerContext.Provider
      value={{
       
        getBombLocation,
        cashOutAmount,
        gameId,
        squares,
        numBombs,
        setNumBombs,
        betAmount,
        setBetAmount,
        multiplier,
        gameOver,
        setGameOver,
        win,
        winVal,
        setWin,
        score,
        setScore,
        incrementAmount,
        setFinalBetAmount,
        resetAllStates,
        loss,setLoss,
        updateGame,
      }}
    >
      {children}
    </DiamondMinerContext.Provider>
  );
};

export default DiamondMinerContext;
