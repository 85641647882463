import './styleG/App.css';
// import Login from './pages/Login/Login';
import { UserProvider } from './pages/Login/UserContext';
import Main from './pages/Main/Main';
function App() {
  
  return (
    <UserProvider>
    <div className="App">
      <Main/>
    </div>
    </UserProvider>
  );
}

export default App;
