import React from "react";
import "./Diamond_Miner_Main.css";
import { Link } from "react-router-dom";
import Button from "../../components/Helper/Button";
import BettingPad from "./BettingPad.jsx";
import GamePad from "./GamePad.jsx";
import { DiamondMinerProvider }  from "./DiamondMinerContext.jsx";

function DiamondMinerMain() {
  return (
    <DiamondMinerProvider>
      <div className="DiamondMinerMain">
        <BettingPad />
        <GamePad />
        <Link to="/">
          <Button addClass={"buttonposition"} text="Back" />
        </Link>
        
      </div>
    </DiamondMinerProvider>
  );
}

export default DiamondMinerMain;
